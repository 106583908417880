.App {
    width: 100%;
}

.row {
    display: flex;
    flex-direction: row;
}

.row .col {
    display: flex;
    flex-direction: column;
}

a {
    color: var(--blue);
    text-decoration: none;
}

h1 {
    font-family: EvangelionRegular;
    font-weight: 100;
    padding: 0;
    margin: 0.2em;
    font-size: 3.5em;
    color: var(--blue);
}
h2 {
    font-family: EvangelionItalic;
    font-weight: 100;
    padding: 0;
    margin: 0.2em;
    font-size: 2.5em;
    color: var(--blue);
}

hr {
    border-bottom: 8px solid var(--yellow);
    border-style: solid;
    color: var(--black);
    margin: 2em 0;
}

.bottom-copy {
    text-align: center;
}

.Main .right {
    margin: 0 auto;
}

.Form table {
    width: 100%;
}

.Form th {
    text-align: right;
    border-right: 1px solid var(--blue);
    padding-right: 0.4em;
}

input[type="text"] {
    border: none;
    border-bottom: 1px solid var(--blue);
    background-color: var(--black);
    color: var(--yellow);
    outline: none;
    font-family: 'SupplyMono', monospace;
    font-size: 2.4em;
    width: 100%;
    text-align: left;
}

input[type="checkbox"] {
    margin: 0.5em;
    width: 3em;
    height: 3em;
}

textarea {
    border: none;
    border-bottom: 1px solid var(--blue);
    background-color: var(--black);
    color: white;
    outline: none;
    font-family: 'SupplyMono', monospace;
    font-size: 1.2em;
    width: 100%;
    vertical-align: bottom;
}

.Poster {
    width: 500px;
    margin: 20px auto;
    border: 2px solid var(--blue);
    padding: 30px;
}

.Poster h1 {
    text-align: center;
    font-size: 5em;
    font-weight: 150;
}

.imgWrapper {
    margin: 0 auto;
    border: 4px solid var(--yellow);
    padding: 14px;
    width: 450px;
    height: 450px;
    position: relative;
}

img.runner {
    width: 450px;
    height: 450px;
    border-radius: 1px;
    opacity: 0.85;
}

.imgWrapper .runner-id {
    position: absolute;
    top: 20px;
    left: 20px;
    color: var(--yellow);
    text-shadow: 1px 1px 2px var(--black);
    font-size: 2.8em;
}

.imgWrapper .talent {
    position: absolute;
    top: 20px;
    right: 20px;
    color: var(--yellow);
    text-shadow: 1px 1px 2px var(--black);
    font-size: 2.0em;
    text-align: right;
}

.imgWrapper .talent .notoriety {
    font-size: 0.7em;
}

.imgWrapper .inside-text {
    color: var(--yellow);
    text-shadow: 1px 1px 2px var(--black);
    font-size: 1.8em;
    text-align: left;
}

.imgWrapper .inside-text p {
    margin: 0;
}

.imgWrapper .inside-left {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.imgWrapper .inside-right {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.outside-text h1 {
    text-transform: none;
    font-size: 3.5em;
    font-weight: 100;
    font-style: normal;
}
